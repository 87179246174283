import { useTheme } from '@emotion/react';
import { LiveHelp, Payments, Security } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  createMatch,
  requestQuote,
  updateIsConvertedStatus,
} from '../../services/CustomerService';
import { getUserDetails } from '../../services/UserDetailsService';
import { Materials } from '../../utils/app.enums';
import { brandImageMap, isLoggedIn } from '../../utils/utils';
import ArrangeVisitDialog from '../ui/ArrangeVisitDialog';
import ArrangeVisitFeedback from '../ui/arrange-visit-feedback/ArrangeVisitFeedback';

const OffersNearYou = ({
  recommendations,
  propertyDetails,
  userDetails,
  district,
  state,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [selectedRecommendationId, setSelectedRecommendationId] =
    useState(null);
  const [arrangedVisits, setArrangedVisits] = useState({});
  const [isArrangeVisitFeedbackOpen, setIsArrangeVisitFeedbackOpen] =
    useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);

  const openArrangeSiteVisit = (vendorId, configId, recommendationId) => {
    setSelectedVendorId(vendorId);
    setSelectedConfigId(configId);
    setSelectedRecommendationId(recommendationId);
    setIsDialogOpen(true);
  };

  const closeArrangeSiteVisit = (isVisitCreated, matchId) => {
    setIsDialogOpen(false);
    setSelectedVendorId(null);
    setSelectedConfigId(null);
    if (isVisitCreated) {
      setArrangedVisits({
        ...arrangedVisits,
        [selectedRecommendationId]: true,
      });
      setSelectedMatchId(matchId);
      setIsArrangeVisitFeedbackOpen(true);
    }
  };

  const openVendorProfile = (vendor) => {
    const vendorNameEncoded = vendor?.establishmentName.replace(/\s+/g, '-');
    const vendorInfo = `${vendorNameEncoded}_${vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  const closeArrangeVisitFeedback = () => {
    setIsArrangeVisitFeedbackOpen(false);
  };

  const featuresChipData = [
    { label: 'Payment protection', icon: <Security /> },
    { label: 'End to end support', icon: <LiveHelp /> },
    { label: 'EMI starting at 7%', icon: <Payments /> },
  ];

  const getMatchingPanelImages = (brands, imageMap) => {
    const brandArray = brands
      .split('/')
      .map((brand) => brand.trim().toLowerCase());
    return brandArray.map((brand) => imageMap[brand]).filter(Boolean);
  };

  const handleShortlist = async (
    vendorId = null,
    customerId = null,
    recommendationId,
    configId,
  ) => {
    const res = await requestQuote(customerId, vendorId);
    await createMatch(customerId, vendorId, configId);
    await updateIsConvertedStatus(recommendationId, true);
    if (res) {
      toast.success('Quote requested successfully');
    }
    return;
  };

  return (
    <>
      <Box>
        <Stack mb={6}>
          <Stack width={'100%'} gap={0.5}>
            {recommendations.map((offer, index) => {
              const plantInfo = offer?.proposalConfig?.config?.plantInfo;
              const pricing = offer?.proposalConfig?.config?.pricing;
              const postGstCost = Math.round(
                pricing?.installationCost * (1 + pricing?.gst / 100),
              );
              const finalCostAfterSubsidy = pricing?.totalCost - 3000;
              const solarPanelObj =
                offer?.proposalConfig?.config?.materials?.find(
                  (material) => material.product === Materials.SOLAR_PANEL,
                );
              const solarPanelManufacturerImages = getMatchingPanelImages(
                solarPanelObj?.brand,
                brandImageMap,
              );
              const inverterObj =
                offer?.proposalConfig?.config?.materials?.find(
                  (material) => material.product === Materials.INVERTER,
                );
              const inverterImages = getMatchingPanelImages(
                inverterObj?.brand,
                brandImageMap,
              );
              const materialsCount =
                offer?.proposalConfig?.config?.materials?.length;
              return (
                <Accordion
                  defaultExpanded
                  key={index}
                  sx={{
                    width: '100%',
                    boxShadow: 'none',
                    alignContent: 'flex-start',
                    paddingInline: { sm: '20px', xs: '2px' },
                    border: '1px solid lightGray',
                    '.MuiAccordionSummary-root': {
                      alignItems: 'start',
                    },
                    borderRadius: '9px !important',
                    '.MuiAccordionSummary-expandIconWrapper': {
                      margin: '13px 0',
                      marginLeft: '4px',
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.h6.fontSize,
                          fontWeight: 'bold',
                        }}
                      >
                        {plantInfo?.plantSize}kW{' '}
                        {plantInfo?.plantType?.toLowerCase()}
                      </Typography>
                      <br />
                      <Stack direction={'column'} flex={1}>
                        <Link
                          sx={{
                            //textDecorationLine: 'none',
                            color: theme.palette.gray,
                          }}
                          variant='subtitle2'
                          onClick={() => openVendorProfile(offer?.vendor)}
                        >
                          by {offer?.vendor?.establishmentName}
                          <br />
                          Serves in {offer.proposalConfig.district},{' '}
                          {offer.proposalConfig.state}
                        </Link>
                      </Stack>
                    </Box>
                    <Stack
                      //direction={isMobile ? 'column' : 'row-reverse'}
                      alignItems={'center'}
                      spacing={2}
                    >
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: {
                            sx: theme.typography.h6.fontSize,
                            sm: theme.typography.h5.fontSize,
                          },
                        }}
                      >
                        ₹ {finalCostAfterSubsidy?.toLocaleString('en-IN')}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container mt={2} alignItems={'start'}>
                      <Grid item xs={6}>
                        <Typography
                          fontWeight='bold'
                          sx={{ fontSize: theme.typography.h6.fontSize, mb: 2 }}
                        >
                          Solar panels <br />
                        </Typography>
                        {/* Display images if available, otherwise show brand names */}
                        {solarPanelManufacturerImages.length > 0 ? (
                          <Stack direction='row' flexWrap='wrap' spacing={1}>
                            {solarPanelManufacturerImages.map(
                              (image, index) => (
                                <img
                                  key={index}
                                  src={image}
                                  style={{ width: '100px' }}
                                  alt={`Solar panel from manufacturer ${index + 1}`}
                                />
                              ),
                            )}
                          </Stack>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: theme.typography.body1.fontSize,
                              mt: 0,
                            }}
                          >
                            {solarPanelObj?.brand?.split('/').join(', ')}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography variant='body1'>
                          {solarPanelObj?.description} <br />
                          <span
                            style={{
                              fontSize: theme.typography.h6.fontSize,
                              fontWeight: 'bold',
                            }}
                          >
                            x{solarPanelObj?.quantity}
                          </span>
                          <br />
                        </Typography>
                        {solarPanelObj?.warrantyInYears && (
                          <Typography
                            variant='caption'
                            sx={{
                              fontSize: theme.typography.body1.fontSize,
                              mt: 0,
                            }}
                          >
                            {solarPanelObj.warrantyInYears} years warranty
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container mt={3} alignItems={'start'}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.h6.fontSize,
                            mb: 2,
                          }}
                        >
                          Inverter <br />
                        </Typography>

                        {inverterImages.length > 0 ? (
                          <Stack direction='row' flexWrap='wrap' spacing={1}>
                            {inverterImages.map((image, index) => (
                              <img
                                key={index}
                                src={image}
                                style={{ width: '100px' }}
                                alt={`Inverter brand ${index + 1}`}
                              />
                            ))}
                          </Stack>
                        ) : (
                          <Typography
                            variant='caption'
                            sx={{ fontSize: theme.typography.body1.fontSize }}
                          >
                            {inverterObj?.brand?.split('/').join(', ')}{' '}
                            {inverterObj?.description}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.h6.fontSize,
                          }}
                        >
                          x{inverterObj?.quantity} <br />
                        </Typography>
                        <Typography
                          sx={{ fontSize: theme.typography.body1.fontSize }}
                        >
                          {inverterObj?.warrantyInYears} years warranty
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        mt={1.5}
                        sx={{
                          color: theme.palette.gray,
                        }}
                      >
                        <Typography variant='subtitle2'>
                          {materialsCount - 2 > 0
                            ? materialsCount - 2 === 1
                              ? `& ${materialsCount - 2} other component`
                              : `& ${materialsCount - 2} other components`
                            : ''}
                          <br />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ margin: '20px 0px' }} />
                    <Grid container mt={3}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          Payable <br />
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          Less: Subsidy
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          ₹ {postGstCost?.toLocaleString('en-IN')}
                          <br />
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          - ₹{' '}
                          {(
                            pricing?.applicableStateSubsidy +
                            pricing?.applicableCentralSubsidy
                          ).toLocaleString('en-IN')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={1.5} mb={2}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          Net cost
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                          }}
                        >
                          Less: VaySolar cashback
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                            mb: 0.1,
                          }}
                        >
                          ₹ {pricing?.totalCost?.toLocaleString('en-IN')}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: theme.typography.body2.fontSize,
                            mb: 1.5,
                          }}
                        >
                          - ₹ 3,000
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container mt={1.5}>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: theme.typography.body1.fontSize,
                          }}
                        >
                          Final cost
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Typography
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                            fontSize: isMobile
                              ? theme.typography.h6.fontSize
                              : theme.typography.h5.fontSize,
                          }}
                        >
                          ₹ {finalCostAfterSubsidy?.toLocaleString('en-IN')}
                        </Typography>
                      </Grid>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 1,
                          width: '100%',
                          mt: 2,
                        }}
                      >
                        {featuresChipData.map((data, index) => (
                          <Chip
                            key={index}
                            icon={data.icon}
                            label={data.label}
                            size='small'
                            sx={{
                              background: theme.palette.secondary.lighter,
                              flexShrink: 0,
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                    <Stack
                      gap={2}
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      // justifyContent='space-between'
                      pt={5}
                      pb={1.5}
                    >
                      <Button
                        variant='outlined'
                        onClick={() =>
                          openArrangeSiteVisit(
                            offer?.vendor?.id,
                            offer?.proposalConfig?.id,
                            offer?.id,
                          )
                        }
                        disabled={
                          offer?.isConverted || arrangedVisits[offer?.id]
                        }
                      >
                        {offer?.isConverted || arrangedVisits[offer?.id]
                          ? 'Visit requested'
                          : 'Request visit'}
                      </Button>
                      <Button
                        variant='contained'
                        sx={{
                          display: isLoggedIn() ? 'inline-flex' : 'none',
                        }}
                        disabled={offer?.isConverted}
                        onClick={() =>
                          handleShortlist(
                            offer?.vendor?.id,
                            getUserDetails()?.id,
                            offer?.id,
                            offer?.proposalConfig?.id,
                          )
                        }
                      >
                        Shortlist
                      </Button>
                      {/* <Button variant='contained'>Quote details</Button> */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Stack>
      </Box>
      <ArrangeVisitDialog
        open={isDialogOpen}
        handleClose={closeArrangeSiteVisit}
        propertyDetails={propertyDetails}
        vendorId={selectedVendorId}
        userDetails={userDetails}
        configId={selectedConfigId}
        recommendationId={selectedRecommendationId}
      />
      <ArrangeVisitFeedback
        open={isArrangeVisitFeedbackOpen}
        handleClose={closeArrangeVisitFeedback}
        matchId={selectedMatchId}
        district={district}
        state={state}
      />
      <Toaster position='bottom-center' />
    </>
  );
};

export default OffersNearYou;
