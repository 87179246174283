import StarIcon from '@mui/icons-material/Star';
import { Box, Stack, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

const calculateStars = (scaleType) => {
  switch (scaleType) {
    case 'FIVE_STAR':
      return 5;
    case 'THREE_STAR':
      return 3;
    default:
      return 5;
  }
};

const labels = {
  1: 'Very poor',
  2: 'Poor',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

function getLabelText(value) {
  return `${value}, ${labels[value]}`;
}

export default function ExperienceRating({
  scaleType,
  start,
  end,
  setFieldValue,
  title,
  fieldName,
  description,
  ratingLabel = true,
  variant = 'row',
}) {
  const startRating = Number(start);
  const endRating = Number(end);
  const defaultValue = 0;
  const numberOfStar = calculateStars(scaleType);
  const theme = useTheme();
  const [hover, setHover] = useState(-1);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setFieldValue(fieldName, getLabelText(defaultValue));
  }, [defaultValue, fieldName, setFieldValue]);

  useEffect(() => {
    if (setFieldValue && ratingLabel) {
      setFieldValue(fieldName, getLabelText(value));
    } else {
      setFieldValue(fieldName, value);
    }
  }, [value, setFieldValue, fieldName, ratingLabel]);

  const rowVariant = () => (
    <Stack gap={'2px'}>
      <Stack
        direction={variant}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
              md: theme.typography.h5.fontSize,
            },
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>

        <Rating
          defaultValue={defaultValue}
          max={numberOfStar}
          name='hover-feedback'
          size='large'
          value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
        />

        {ratingLabel && (
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h5.fontSize,
              },
              fontWeight: theme.typography.h1.fontWeight,
            }}
          >
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Typography>
        )}
      </Stack>
      {description && (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.subtitle2.fontSize,
              md: theme.typography.subtitle2.fontSize,
            },
            fontWeight: theme.typography.h3.fontWeight,
          }}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );

  const columnVariant = () => (
    <Stack
      gap={2}
      direction={'column'}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: theme.typography.h5.fontSize,
            md: theme.typography.h5.fontSize,
          },
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <Rating
          defaultValue={defaultValue}
          max={numberOfStar}
          name='hover-feedback'
          size='large'
          value={value}
          precision={1}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />}
        />
        {ratingLabel && (
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h5.fontSize,
              },
              fontWeight: theme.typography.h1.fontWeight,
            }}
          >
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  return <>{variant === 'column' ? columnVariant() : rowVariant()}</>;
}
