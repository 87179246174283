export const REVIEW_REASON_CODE = [
  {
    title: 'Timeliness',
    description: 'gave meaningful & timely updates',
    scaleType: 'FIVE_STAR',
    scaleValue: {
      startsAt: '1',
      endsAt: '5',
    },
    formikFieldName: 'timeliness',
    key: 'TIMELINESS',
  },
  {
    title: 'Price as quoted',
    description: 'paid price matched what was quoted',
    scaleType: 'FIVE_STAR',
    scaleValue: {
      startsAt: '1',
      endsAt: '5',
    },
    formikFieldName: 'price_as_quoted',
    key: 'PRICE_AS_QUOTED',
  },
  {
    title: 'Sales support',
    description: 'sales process, post sales support',
    scaleType: 'FIVE_STAR',
    scaleValue: {
      startsAt: '1',
      endsAt: '5',
    },
    formikFieldName: 'sales_support',
    key: 'SALES_SUPPORT',
  },
];
