import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import React, { useState } from 'react';

const ImagePreviewModal = ({ open, onClose, imageSrcArray }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = imageSrcArray.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    setActiveStep(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      sx={{
        borderRadius: '8px',
        backdropFilter: 'blur(2px)',
        
      }}
      PaperProps={{
        style: {
          boxShadow: 'none',
        },
      }}
    >
      <DialogContent
        sx={{
          position: 'relative',
          bgcolor: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          padding: '0px 0px'
        }}
      >
        <IconButton
          aria-label='previous'
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ 
            zIndex: 1, 
            fontSize: {xs: '1.5rem', sm: '3rem' , md: '3rem', lg: '3rem'}, // Increase font size
          }}
        >
          <KeyboardArrowLeft fontSize="inherit" />
        </IconButton>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height={{ sm: '50vh', xs: '40vh', lg: '80vh' }}
        >
          <img
            src={imageSrcArray[activeStep]}
            alt={`Preview`}
            style={{ maxWidth: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Box>
        <IconButton
          aria-label='next'
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ 
            zIndex: 1, 
            fontSize: {xs: '1.5rem', sm: '3rem' , md: '3rem', lg: '3rem'}, // Increase font size
            // Adjust padding for larger button area
          }}
        >
          <KeyboardArrowRight fontSize="inherit" />
        </IconButton>
      </DialogContent>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{ position: 'absolute', top: 6, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default ImagePreviewModal;
