import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  CircularProgress,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WriteReviewImage from '../Image/writeReview.png';
import { ReviewLayout } from '../layoutV2/ReviewLayout';
import { getVendorName } from '../services/CustomerService';

function WriteReviewPage() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [vendorData, setVendorData] = useState(null);
  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    const onMount = () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const extractedVendorId = params.get('vendorId');
      const extractedReviewId = params.get('reviewId');
      setVendorId(extractedVendorId);
      setReviewId(extractedReviewId);
    };
    onMount();
  }, []);

  // Fetch vendor data once vendorId and reviewId are available
  useEffect(() => {
    const fetchVendorData = async () => {
      if (vendorId && reviewId) {
        try {
          const data = await getVendorName(reviewId, vendorId);
          setVendorData(data.vendorName);
        } catch (error) {
          console.error('Failed to fetch vendor data:', error);
        }
      }
    };

    fetchVendorData();
  }, [vendorId, reviewId]); // Depend on vendorId and reviewId

  const WriteReview = () => {
    const handleSubmit = () => {
      const newPath = '/write-now';

      history({
        pathname: newPath,
        search: location.search,
      });
    };

    return (
      <>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box
            component='main'
            sx={{
              paddingInline: { md: '24px', xs: '12px' },
            }}
          >
            <Box display={'flex'}>
              <Box
                pt={2}
                gap={10}
                sx={{
                  flexGrow: 1,
                  pb: 2,
                  display: 'grid',
                }}
              >
                <ListItemText
                  sx={{
                    marginTop: '45px',
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    paddingLeft: '20px',
                  }}
                  primary={
                    <>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: theme.typography.h5.fontSize,
                            md: theme.typography.h4.fontSize,
                          },
                          fontWeight: theme.typography.h1.fontWeight,
                          color: theme.palette.primary.main,
                        }}
                      >
                        {vendorData}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: theme.typography.h5.fontSize,
                            md: theme.typography.h4.fontSize,
                          },
                          fontWeight: theme.typography.h1.fontWeight,
                          color: theme.palette.primary.main,
                        }}
                      >
                        selected you to
                      </Typography>
                    </>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.h5.fontSize,
                          md: theme.typography.h4.fontSize,
                        },
                        fontWeight: theme.typography.h1.fontWeight,
                      }}
                    >
                      write a review
                    </Typography>
                  }
                />

                <Box
                  display='flex'
                  alignItems='center'
                  sx={{
                    color: theme.palette.primary.main,
                    marginBottom: '158px',
                  }}
                >
                  <AdminPanelSettingsIcon
                    fontSize='100px'
                    sx={{ fontSize: '80px' }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.h5.fontSize,
                        md: theme.typography.h4.fontSize,
                      },
                      fontWeight: theme.typography.h1.fontWeight,
                    }}
                  >
                    Verified reviews help Bharat make correct choices
                  </Typography>
                </Box>
              </Box>
              <Box
                component='img'
                height={{ lg: '365px', md: '350px' }}
                src={WriteReviewImage}
                display={{ xs: 'none', md: 'block' }}
                paddingTop={'35px'}
              />
            </Box>
            <Stack>
              <Stack alignItems={'flex-end'}>
                <Button
                  type='submit'
                  variant='contained'
                  disableElevation
                  endIcon={
                    <ArrowForwardIos sx={{ height: '16px', width: '20px' }} />
                  }
                  onClick={() => handleSubmit()}
                  sx={{
                    width: '170px',
                  }}
                >
                  write now
                </Button>
                <Typography variant='caption'>for verification</Typography>
              </Stack>
            </Stack>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <ReviewLayout title='Write a Review'>{WriteReview()}</ReviewLayout>
    </>
  );
}

export default WriteReviewPage;
