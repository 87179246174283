import { jwtDecode } from 'jwt-decode';
import ReactGA4 from 'react-ga4';

import deyeImage from '../Image/inverter/deye.png';
import havellsImage from '../Image/inverter/havells.png';
import ksolareImage from '../Image/inverter/ksolare.jpeg';
import polycabImage from '../Image/inverter/polycab.png';
import solisImage from '../Image/inverter/solis.png';
import vsoleImage from '../Image/inverter/vsole.png';
import adaniImage from '../Image/panel_manufacturers/adani.jpg';
import apsImage from '../Image/panel_manufacturers/aps.png';
import gautumImage from '../Image/panel_manufacturers/gautam.jpg';
import goldiImage from '../Image/panel_manufacturers/goldi.jpg';
import inaSolarImage from '../Image/panel_manufacturers/ina-solar.png';
import loomImage from '../Image/panel_manufacturers/loom.jpg';
import luminousImage from '../Image/panel_manufacturers/luminous.png';
import microtekImage from '../Image/panel_manufacturers/microtek.png';
import patanjaliImage from '../Image/panel_manufacturers/patanjali.jpg';
import renewsysImage from '../Image/panel_manufacturers/renewsys.jpg';
import saatvikImage from '../Image/panel_manufacturers/saatvik.jpg';
import tataImage from '../Image/panel_manufacturers/tata.jpg';
import utlImage from '../Image/panel_manufacturers/utl.png';
import vikramImage from '../Image/panel_manufacturers/vikram.jpg';
import waareeImage from '../Image/panel_manufacturers/waaree.jpg';

export const isDevEnv = process.env.REACT_APP_ENVIRONMENT === 'development';

export const toSentenceCase = (sentence) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
};

export const isLoggedIn = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

export const trackGoogleAnalyticsEvent = (category, event_name, data) => {
  let event_params = {
    category,
    ...data,
  };
  // Send GA4 Event
  if (!isDevEnv) {
    ReactGA4.event(event_name, event_params);
  }
};

export const calculateYears = (establishmentDate) => {
  const currentDate = new Date();
  const establishmentDay = new Date(establishmentDate);
  const years = currentDate.getFullYear() - establishmentDay.getFullYear();
  const monthDifference = currentDate.getMonth() - establishmentDay.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 &&
      currentDate.getDate() < establishmentDay.getDate())
  ) {
    return years - 1;
  }

  return years;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-IN', options);
};

export const truncateFileName = (fileName, length = 20) => {
  if (fileName.length <= length) return fileName;
  return `${fileName.substr(0, length)}...`;
};

export const truncateText = (text, maxLength = 20) => {
  if (text.length <= maxLength) return text;
  return `${text.substr(0, maxLength)}...`;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  const countryCode = '+91';
  const formattedNumber = phoneNumber.replace(
    /^(\d{2})(\d{5})(\d{5})$/,
    `${countryCode} $2 $3`,
  );
  return formattedNumber;
};

export const brandImageMap = {
  adani: adaniImage,
  aps: apsImage,
  gautum: gautumImage,
  goldi: goldiImage,
  inasolar: inaSolarImage,
  loom: loomImage,
  luminous: luminousImage,
  microtek: microtekImage,
  patanjali: patanjaliImage,
  renewsys: renewsysImage,
  saatvik: saatvikImage,
  tata: tataImage,
  utl: utlImage,
  vikram: vikramImage,
  waaree: waareeImage,
  deye: deyeImage,
  havells: havellsImage,
  ksolare: ksolareImage,
  polycab: polycabImage,
  solis: solisImage,
  vsole: vsoleImage,
};
