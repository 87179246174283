import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { PlantType } from '../../utils/app.enums';

const EditPlantDetailsDialog = ({
  open,
  handleClose,
  propertyDetails,
  handleSave,
}) => {
  const [plantSize, setPlantSize] = useState(propertyDetails.plantSize);
  const [plantType, setPlantType] = useState(propertyDetails.plantType);

  const handlePlantSizeChange = (event) => {
    setPlantSize(event.target.value);
  };

  const handlePlantTypeChange = (event) => {
    setPlantType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave({ plantSize, plantType });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Edit Plant Details</DialogTitle>
        <DialogContent>
          <TextField
            label='Plant Size (kW)'
            type='number'
            value={plantSize}
            onChange={handlePlantSizeChange}
            fullWidth
            margin='dense'
            required
          />
          <FormControl fullWidth margin='dense' required>
            <InputLabel id='plant-type-label'>Plant Type</InputLabel>
            <Select
              labelId='plant-type-label'
              id='plant-type'
              value={plantType}
              onChange={handlePlantTypeChange}
              label='Plant Type'
            >
              {Object.values(PlantType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditPlantDetailsDialog;
