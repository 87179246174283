import { GroupAdd } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import logo from '../../../Image/logo512.png';
import { saveBookingPreference } from '../../../services/CustomerService';

export default function GroupBooking({
  onSkip,
  onSubmit,
  district,
  state,
  matchId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async () => {
    try {
      await saveBookingPreference(matchId);
      onSubmit();
    } catch (error) {
      console.error('Failed to save booking preference', error);
    }
  };
  return (
    <Stack
      alignItems={'center'}
      gap={isMobile ? 4 : 2}
      sx={{
        mt: isMobile ? '96px' : '48px',
      }}
    >
      <img
        src={logo}
        alt='Logo'
        style={{
          width: '150px',
          height: '150px',
        }}
      />
      <Typography align='center' variant='h6'>
        Your trusted end-to-end <br /> solar partner.
      </Typography>
      <Box
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          padding: '24px',
          backgroundColor: 'white',
          width: { xs: '100%', sm: '400px' },
          margin: '20px 0',
        }}
      >
        <Typography variant='h5' mb={4}>
          <span style={{ fontWeight: 'bold' }}> 3 neighbors </span>
          <br /> looking in {district}, {state}
        </Typography>
        <Typography variant='h6' mb={4}>
          buy together, upto ₹ 20,000 off
        </Typography>
        <Button
          variant='contained'
          sx={{ textTransform: 'none' }}
          size='large'
          onClick={handleSubmit}
          startIcon={<GroupAdd />}
        >
          Join the list
        </Button>
      </Box>
      <Stack>
        <Button sx={{ textTransform: 'none' }} size='large' onClick={onSkip}>
          Not interested
        </Button>
      </Stack>
    </Stack>
  );
}
