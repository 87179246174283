import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ExperienceRating from '../components/reviewComponent/ReviewStar';
import TextReview from '../components/reviewComponent/ReviewTextBox';
import WriteNowImg from '../Image/writeReview.png';
import { ReviewLayout } from '../layoutV2/ReviewLayout';
import { getVendorName, updateFeedback } from '../services/CustomerService';
import { REVIEW_REASON_CODE } from '../utils/ReviewCodes';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
// import { truncateText } from '../utils/Utils';

function WriteNowPage() {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const [vendorId, setVendorId] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [vendorData, setVendorData] = useState('');
  const [reviewConfigData, setReviewConfigData] = useState({});
  const [reasonCode, setReasonCode] = useState([]);

  const formik = useFormik({
    initialValues: {
      reviewComments: '',
      fullName: '',
      rating: '',
      timeliness: '',
      price_as_quoted: '',
      sales_support: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name can only contain letters and spaces')
        .min(2, 'Name must be at least 2 characters')
        .max(50, 'Name can be at most 50 characters')
        .required('Name is required'),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const uploadPromises = [];

        const reasonCode = REVIEW_REASON_CODE.reduce((acc, item) => {
          acc[item.key] = values[item.formikFieldName];
          return acc;
        }, {});

        const feedBackRequest = {
          reviewerName: values.fullName,
          reasonCode: reasonCode,
          rating: values.rating,
          reviewComments: values.reviewComments,
        };
        uploadPromises.push(
          updateFeedback(vendorId, reviewId, feedBackRequest),
        );
        await Promise.all(uploadPromises);
        const newPath = '/verify-review';

        history({
          pathname: newPath,
          search: location.search,
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      setIsLoading(false);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const onMount = () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const extractedVendorId = params.get('vendorId');
      const extractedReviewId = params.get('reviewId');
      setVendorId(extractedVendorId);
      setReviewId(extractedReviewId);
    };
    onMount();
  }, []);

  // Fetch vendor data once vendorId and reviewId are available
  useEffect(() => {
    const fetchVendorData = async () => {
      if (vendorId && reviewId) {
        try {
          const data = await getVendorName(reviewId, vendorId);
          setVendorData(data.vendorName);
          setReviewConfigData(data.reviewConfig);
          setReasonCode(data.reviewConfig.config.reasonCode);
        } catch (error) {
          console.error('Failed to fetch vendor data:', error);
        }
      }
    };

    fetchVendorData();
  }, [vendorId, reviewId]); // Depend on vendorId and reviewId

  const WriteNow = () => {
    return (
      <>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box
            component='main'
            sx={{
              paddingInline: { md: '24px', xs: '12px' },
            }}
          >
            <Box display={'flex'}>
              <Box
                pt={2}
                gap={3}
                sx={{
                  flexGrow: 1,
                  pb: 2,
                  display: 'grid',
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: theme.typography.h5.fontSize,
                      md: theme.typography.h5.fontSize,
                    },
                    fontWeight: theme.typography.h1.fontWeight,
                    color: theme.palette.primary.main,
                    marginTop: '32px',
                  }}
                >
                  Write a review for {vendorData}
                </Typography>

                <Box display={'grid'} gap={4.5}>
                  <TextReview
                    fieldName='fullName'
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    showCharCount={false}
                    limit={20}
                    label={'Your name'}
                    row={1}
                  />
                </Box>
                {reviewConfigData?.config?.scaleType ? (
                  <ExperienceRating
                    scaleType={
                      reviewConfigData?.config?.scaleType || 'FIVE_STAR'
                    }
                    start={
                      reviewConfigData?.config?.scaleValue?.startsAt || '1'
                    }
                    end={reviewConfigData?.config?.scaleValue?.endsAt || '1'}
                    title={'Rate the experience'}
                    fieldName={'rating'}
                    setFieldValue={formik.setFieldValue}
                    values={formik.values}
                    variant={'column'}
                  />
                ) : (
                  ''
                )}
                <TextReview
                  fieldName='reviewComments'
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                  showCharCount={true}
                  limit={100}
                  label={'Review comments'}
                  row={6}
                />
                {REVIEW_REASON_CODE.filter((item) =>
                  reasonCode.includes(item.key),
                ) // Filter by reason codes
                  .map((item) => (
                    <ExperienceRating
                      key={item.key}
                      scaleType={item.scaleType}
                      start={item.scaleValue?.startsAt}
                      end={item.scaleValue?.endsAt}
                      title={item.title}
                      fieldName={item.formikFieldName}
                      setFieldValue={formik.setFieldValue}
                      values={formik.values}
                      description={item.description}
                      ratingLabel={false}
                      variant={'row'}
                    />
                  ))}
              </Box>

              <Box
                component='img'
                height={{ lg: '365px', md: '350px' }}
                src={WriteNowImg}
                display={{ xs: 'none', md: 'block' }}
                paddingTop={'35px'}
              />
            </Box>
            <Stack>
              <Box display='flex' justifyContent='end' alignItems={'start'}>
                <Stack alignItems={'center'}>
                  {console.log(formik.values.price_as_quoted)}
                  <Button
                    type='submit'
                    variant='contained'
                    disabled={
                      !formik.isValid ||
                      formik.values.rating == '0, undefined' ||
                      formik.values.price_as_quoted == '0' ||
                      formik.values.timeliness == '0' ||
                      formik.values.sales_support == '0'
                    }
                    sx={{
                      paddingLeft: '40px',
                      paddingRight: '40px',
                    }}
                    disableElevation
                    endIcon={
                      <ArrowForwardIos sx={{ height: '16px', width: '20px' }} />
                    }
                    onClick={formik.handleSubmit}
                  >
                    Save
                  </Button>
                  <Typography variant='caption'>for verification</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        )}
      </>
    );
  };

  return <ReviewLayout title='Write Now'>{WriteNow()}</ReviewLayout>;
}

export default WriteNowPage;
