import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateInstallationTimeline, getProjectEstimate } from '../../services/CustomerService';
import { useSearchAnimate } from '../../store/SearchAnimateStore';
import { InstallationTimeline } from '../../utils/app.enums';
import PropertyDetails from '../ui/PropertyDetails';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import EnergySavingsLeafRoundedIcon from '@mui/icons-material/EnergySavingsLeafRounded';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import { ChartsReferenceLine } from '@mui/x-charts';
import { toSentenceCase } from '../../utils/utils';

const isActivelyLooking = (installationTimeline) => {
  const timelines = ['1 month', '1-3 months', '3+ months'];
  return timelines.includes(installationTimeline);
};

const InstallationTimelineDialog = ({
  open,
  handleClose,
  handleUpdateStatus,
  status,
}) => {
  const { t } = useTranslation();
  const [additionalComments, setAdditionalComments] = useState('');
  const [selectedRadioOption, setSelectedRadioOption] = useState('');

  const installationTimelineOptions = [
    {
      name: t('main.installationTimeline.options.opt1'),
      value: InstallationTimeline.ONE_MONTH,
    },
    {
      name: t('main.installationTimeline.options.opt2'),
      value: InstallationTimeline.ONE_THREE_MONTHS,
    },
    {
      name: t('main.installationTimeline.options.opt3'),
      value: InstallationTimeline.THREE_PLUS_MONTHS,
    },
  ];
  const feedbackOptions = [
    {
      name: 'Budget Issue',
      value: 'Budget issue',
    },
    {
      name: 'Price too high',
      value: 'Price too high',
    },
    {
      name: 'Installed already elsewhere',
      value: 'Installed already elsewhere',
    },
    {
      name: 'Electricity connection issues',
      value: 'Electricity connection issues',
    },
    {
      name: "Didn't find an installer",
      value: "Didn't find an installer",
    },
    {
      name: 'Other',
      value: 'Other',
    },
  ];

  const options = isActivelyLooking(status)
    ? feedbackOptions
    : installationTimelineOptions;

  const handleDialogUpdateStatus = async () => {
    if (selectedRadioOption === 'Other' && additionalComments === '') {
      alert('Input cannot be empty!');
      return;
    }
    let newStatus;
    let newFeedback;
    if (isActivelyLooking(status)) {
      newStatus = InstallationTimeline.NOT_LOOKING;
      newFeedback = selectedRadioOption;
    } else {
      newStatus = selectedRadioOption;
    }
    await handleUpdateStatus(newStatus, newFeedback, additionalComments);
    setAdditionalComments('');
  };

  useEffect(() => {
    setSelectedRadioOption(options[0].value);
  }, [status]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '8px',
          paddingBlock: '16px',
          paddingInline: { sm: '16px', xs: '3px' },
        },
      }}
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <DialogTitle component={'div'}>
        <Typography variant='h6' fontWeight={'bold'}>
          {isActivelyLooking(status)
            ? 'Not looking for solar anymore'
            : 'Update Installation Timeline'}
        </Typography>
        <Typography
          variant='body2'
          pt='3px'
          sx={{
            color: 'text.secondary',
          }}
        >
          {isActivelyLooking(status)
            ? 'Please choose reason for not installing?'
            : 'When do you want to install?'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          name='selectedOption'
          value={selectedRadioOption}
          onChange={(e) => setSelectedRadioOption(e.target.value)}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={
                <Radio
                  size='small'
                  sx={{
                    paddingBlock: '6px',
                  }}
                />
              }
              label={<Typography variant='body2'>{option.name}</Typography>}
            />
          ))}
        </RadioGroup>
        {isActivelyLooking(status) && (
          <Collapse
            in={selectedRadioOption === 'Other'}
            sx={{
              mt: 1,
            }}
            unmountOnExit
            collapsedSize={0}
          >
            <TextField
              multiline
              rows={3}
              fullWidth
              variant='outlined'
              placeholder='Add additional comments'
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
            />
          </Collapse>
        )}
        <Stack
          direction={'row'}
          pt={'22px'}
          justifyContent={'flex-end'}
          spacing={1}
        >
          <Button onClick={handleClose} size='small'>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleDialogUpdateStatus}
            size='small'
            endIcon={<UpgradeIcon />}
            disableElevation
          >
            Update
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const BenefitsDialog = ({
  open, handleClose, dataField, propertyDetails
}) => {
  const [selectedChip, setSelectedChip] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dataToShow = [
    {
      title: "Summary",
      icon: <SummarizeOutlinedIcon fontSize='small' />
    },
    {
      title: "First year",
      icon: <TrendingUpOutlinedIcon fontSize='small' />
    },
    {
      title: "Lifetime",
      icon: <TrendingUpOutlinedIcon fontSize='small' />
    }
  ]


  const LastYearData = () => {

    return (
      <Stack pt={'10px'}>
        <BarChart
          xAxis={[{ scaleType: 'band', data: dataField !== "" ? Object.values(dataField.data.noLoanLifetimeProjection.year) : [] }]}
          series={[{ data: dataField !== "" ? Object.values(dataField.data.noLoanLifetimeProjection.cumulativeSavings) : [], label: 'Cumulative savings', color: '#7CC674' }]}
          height={300}
          leftAxis={null}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'top', horizontal: 'right' },
              labelStyle: {
                fontSize: '13px',
              },
              padding: 0,
              itemMarkWidth: 13,
              itemMarkHeight: 13,
            },


          }}

        >
          {dataField !== "" && <ChartsReferenceLine y={dataField.data.noLoanLifetimeProjection.costOfProject["0"]} label="Project cost" labelStyle={{ fontSize: '12px' }} labelAlign="start" />}
        </BarChart>
        <TableContainer component={'div'} sx={{
          paddingInline: { sm: '16px', xs: '3px' },
        }}>
          <Table sx={{ minWidth: '100%' }} aria-label="simple table" size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Year</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Generation</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Savings</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Total savings</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataField !== "" &&
                Object.values(dataField.data.noLoanLifetimeProjection.year).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>
                      {row}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>{(Math.round(dataField.data.noLoanLifetimeProjection.units_produced_solar[`${index}`])).toLocaleString('en-IN')} units</TableCell>
                    <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>₹ {(Math.round(dataField.data.noLoanLifetimeProjection.savings[`${index}`])).toLocaleString('en-IN')}</TableCell>
                    <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>₹ {(Math.round(dataField.data.noLoanLifetimeProjection.cumulativeSavings[`${index}`])).toLocaleString('en-IN')}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  }

  const FirstYearData = () => {

    const rows = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (
      <Stack position={'relative'} pt={'10px'}>
        <BarChart
          xAxis={[{ scaleType: 'band', data: dataField !== "" ? rows : [] }]}
          series={[
            { data: dataField !== "" ? dataField.data.summaryTable.BillBefore : [], label: 'Bills without solar', color: '#B8BBBE' },
            { data: dataField !== "" ? dataField.data.summaryTable.BillAfter : [], color: '#7CC674', label: 'Bills with solar' },
          ]}
          borderRadius={10}
          height={300}
          leftAxis={null}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'top', horizontal: 'right' },
              labelStyle: {
                fontSize: '13px',
              },
              padding: 0,
              itemMarkWidth: 13,
              itemMarkHeight: 13,
            }
          }}
        />
        <TableContainer component={'div'} sx={{
          paddingInline: { sm: '16px', xs: '3px' },
        }} >
          <Table sx={{ minWidth: '100%' }} aria-label="simple table" size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Month</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Without solar</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>With solar</TableCell>
                <TableCell align="right" sx={{
                  fontWeight: 'bold',
                  fontSize: { sm: '14px', xs: '13px' }
                }}>Savings</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>
                    {row}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>₹{dataField !== "" && (Math.round(dataField.data.summaryTable.BillBefore[index])).toLocaleString('en-IN')}</TableCell>
                  <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>₹{dataField !== "" && (Math.round(dataField.data.summaryTable.BillAfter[index])).toLocaleString('en-IN')}</TableCell>
                  <TableCell align="right" sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}>₹{dataField !== "" && (Math.round(dataField.data.summaryTable.BillBefore[index] - dataField.data.summaryTable.BillAfter[index])).toLocaleString('en-IN')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  }


  const SummaryChart = () => {
    return (
      <Box position={'relative'} mt={'40px'} sx={{
        paddingInline: { sm: '16px', xs: '4px' }
      }}>
        <EnergySavingsLeafRoundedIcon sx={{
          fontSize: '410px',
          position: 'absolute',
          color: '#1B833312',
          width: '100%',
          top: '-50px',
          left: '-140px',

        }} />
        <Grid container height={'100%'} pt={'80px'}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Plant size selected
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' textAlign={'right'} fontWeight={'bold'} pl={'5px'}>
                {dataField !== '' && dataField.output.plantSize}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} pt={'6px'}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Annual generation
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' textAlign={'right'} fontWeight={'bold'} pl={'5px'}>
                {dataField !== '' && (dataField.output.solarProducedUnits).toLocaleString('en-IN')} units
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} pt={'6px'}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Lifetime generation
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' textAlign={'right'} fontWeight={'bold'} pl={'5px'}>
                {dataField !== '' && (dataField.output.lifeTimeProductionRounded).toLocaleString('en-IN')} units
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} pt={'32px'}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Cost of solar
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' textAlign={'right'} fontWeight={'bold'} pl={'5px'}>
                ₹ {dataField !== '' && dataField.output.netCostRange}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} pt={'6px'}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' fontWeight={'bold'}>
                Per unit cost of solar
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' textAlign={'right'} fontWeight={'bold'} pl={'5px'}>
                ₹ {dataField !== '' && (dataField.output.max_cost / dataField.output.lifeTimeProduction).toFixed(0)} per unit
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Box pt={'40px'}>
          <Typography variant='subtitle2' color={'gray'}>Estimated based on
            <br />
            {toSentenceCase(propertyDetails.billMonth) || 'January'} bill of Rs {propertyDetails.billAmount || 2000} on {(propertyDetails.propertyType).toLowerCase() || 'residential'} property in {propertyDetails.address?.state || 'Delhi'}
          </Typography>
        </Box>
      </Box>
    )
  }

  const handleChipClick = (index) => {
    setSelectedChip(index);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '8px',
          paddingBlock: '16px',
          paddingTop: '10px',
          height: { sm: '500px', xs: '100%' },
        },
      }}
      sx={{
        backdropFilter: 'blur(2px)'
      }}
    >
      <DialogTitle component={'div'} position={'relative'} paddingInline={{ sm: '24px', xs: '10px' }} paddingBottom={'0px !important'}>
        <Stack direction={'row'} gap={'10px'} pt={'8px'} sx={{
          paddingInline: { sm: '16px', xs: '3px' },
        }}>
          {
            dataToShow.map((data, i) => {
              return (
                <Chip
                  label={data.title}
                  icon={data.icon}
                  key={i}
                  color={selectedChip === i ? 'primary' : 'default'} // Primary color if selected
                  onClick={() => handleChipClick(i)} // Toggle selection on click
                  sx={{
                    borderRadius: '6em',
                    backgroundColor: selectedChip === i ? 'primary.main' : 'white',
                    border: selectedChip === i ? '1px solid green' : '1px solid gray',
                    color: selectedChip === i ? 'white' : 'gray',
                    '&:hover': {
                      backgroundColor: selectedChip === i ? 'primary.dark' : 'gray.100',
                    },
                  }}
                />
              )
            })
          }
        </Stack>
        <Box textAlign={'right'} top={0} right={{ sm: 20, xs: 4 }} position={'absolute'}>
          <IconButton size='small' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{
        paddingInline: { sm: '24px', xs: '10px' }
      }}>
        {selectedChip === 0 && <SummaryChart />}
        {selectedChip === 1 && <FirstYearData />}
        {selectedChip === 2 && <LastYearData />}
      </DialogContent>
    </Dialog>
  )
}

export default function InstallationStatus({
  propertyDetails,
  installationTimeline = '1 month',
}) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(installationTimeline);
  const [benefitDialogOpen, setBenefitDialogOpen] = useState(false);
  const { setIsLooking } = useSearchAnimate();
  const [expanded, setExpanded] = useState(false);
  const [dataField, setDataField] = useState('');

  useEffect(() => {
    setStatus(installationTimeline);
  }, [installationTimeline]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = async (
    newStatus,
    newFeedback,
    additionalComments,
  ) => {
    setIsLoading(true);
    try {
      handleClose();
      const updatedStatus = await updateInstallationTimeline(
        propertyDetails,
        newStatus,
        newFeedback,
        additionalComments,
      );
      setStatus(updatedStatus.installationTimeline);
      if (
        updatedStatus.installationTimeline === InstallationTimeline.NOT_LOOKING
      ) {
        setIsLooking(false);
      } else {
        setIsLooking(true);
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getBenefitsData = async () => {
    try {
      const region = propertyDetails.address?.state || 'Delhi';
      const sanctionedLoad =
        propertyDetails.sanctionedLoad || 3;
      const propertyType =
        propertyDetails.propertyType || 'RESIDENTIAL';
      const billAmount = propertyDetails.billAmount || 2000;
      const billMonth = propertyDetails.billMonth || 'JANUARY';
      const shadowFreeArea =
        propertyDetails.shadowFreeArea || 0;
      const plantSize = propertyDetails.plantSize || null
      const data = await getProjectEstimate(
        region,
        propertyType,
        sanctionedLoad,
        billAmount,
        shadowFreeArea,
        billMonth,
        plantSize
      );
      if (data.length === 0) throw new Error('No data found');
      const transformedData = data;
      setDataField(transformedData);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (propertyDetails) {
      getBenefitsData();
    }
  }, [])

  return (
    <>
      <InstallationTimelineDialog
        open={open}
        handleClose={handleClose}
        handleUpdateStatus={handleUpdateStatus}
        status={status}
        setStatus={setStatus}
      />
      <BenefitsDialog
        open={benefitDialogOpen}
        handleClose={() => setBenefitDialogOpen(false)}
        dataField={dataField}
        propertyDetails={propertyDetails}
      />
      <Box sx={{
        maxWidth: '1048px', mx: 'auto',
        background: isActivelyLooking(status)
          ? theme.palette.secondary.lighter
          : theme.palette.grayLight,
        borderBottomRightRadius: '12px',
        borderBottomLeftRadius: '12px',
        paddingBottom: '8px',
      }}>
        <Stack

          flex={1}
          paddingInline={'12px'}
          paddingBlock={'10px'}
        >
          <Stack width={'100%'} direction={'row'}
            // alignItems={{ sm: 'center', xs: 'baseline' }}
            alignItems={'center'}
            columnGap={'10px'}
          >
            <Box
              sx={{
                backgroundColor: isActivelyLooking(status)
                  ? theme.palette.warning.secondary
                  : theme.palette.gray,
                animation:
                  isActivelyLooking(status) && 'pulse-animation 2s infinite',
                '@keyframes pulse-animation': {
                  '0%': {
                    boxShadow: `0 0 0 0px ${theme.palette.warning.secondary}`,
                  },
                  '100%': {
                    boxShadow: '0 0 0 12px rgba(0, 0, 0, 0)',
                  },
                },
              }}
              width={'16px'}
              height={'16px'}
              borderRadius={'50%'}
            />
            <Typography variant='body1' color={'gray'}>
              {isActivelyLooking(status) ? (
                <>
                  <b>Actively</b> looking.
                </>
              ) : (
                <>
                  <b>Not</b> looking for solar right now.
                </>
              )}{' '}
              <Link
                onClick={handleClickOpen}
                component={'span'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <b>Update</b> status
              </Link>
            </Typography>
          </Stack>
          <Accordion sx={{
            width: '100%',
            borderRadius: '9px !important',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': {
              display: 'none'
            },
            marginTop: '0px !important',

          }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                paddingInline: { sm: '12px', xs: '8px' },
                '& .MuiAccordionSummary-content': {
                  marginBlock: '16px',
                },
              }}
            >
              <Typography variant='subtitle2' fontWeight={'bold'}>
                {' '}
                {propertyDetails?.sanctionedLoad} kW load
                {propertyDetails?.siteVisitData?.roofTopArea &&
                  `, ${propertyDetails.siteVisitData.roofTopArea} sq ft`}
                {propertyDetails?.siteVisitData?.region &&
                  ` @ ${propertyDetails.siteVisitData.region}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingInline: { sm: '12px', xs: '8px' },
              }}
            >
              <PropertyDetails
                textVariant='primary'
                overrideAlignment='start'
                propertyDetails={propertyDetails}
              />

              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pt={'4px'} >
                {isActivelyLooking(status) &&
                  <Button sx={{ bgcolor: '#1976D20A', px: 3 }} onClick={() => setBenefitDialogOpen(true)}>Benefits</Button>
                }
                <Typography variant='subtitle2' ml={'auto'} textAlign={'start'}>
                  50% complete
                </Typography>
              </Stack>

            </AccordionDetails>
          </Accordion>
          <Stack
            direction='row'
            paddingInline={'12px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'14px'}
          >
            {!expanded && (
              <Typography variant='subtitle2' textAlign={'start'}>
                50% complete
              </Typography>
            )}
          </Stack>
          {isLoading && <CircularProgress size={24} />}
        </Stack>
      </Box>
    </>
  );
}
