export function getUserDetails() {
  // Get the access token from local storage
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken === null) {
    return null;
  }
  // Parse the access token
  const base64Url = accessToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  const data = JSON.parse(jsonPayload);

  // Return the username and email
  return {
    id: data.id,
    name: data.name,
    email: data.email,
    phoneNumber: data.phoneNumber,
  };
}
