import { Button, Rating, Stack } from '@mui/material';
import { useState } from 'react';
import { saveSiteVisitFeedback } from '../../../services/CustomerService';

export default function RateExperience({ onSubmit, onSkip, matchId }) {
  const [rating, setRating] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!rating) return;

    setIsSubmitting(true);
    try {
      await saveSiteVisitFeedback(matchId, rating);
      onSubmit?.(rating);
    } catch (error) {
      console.error('Failed to save rating:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack alignItems={'center'}>
      <Rating
        value={rating}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
        size='large'
        max={3}
        sx={{ mb: 4, fontSize: '48px' }}
      />
      <Button
        variant='contained'
        size='large'
        sx={{ mb: 2, textTransform: 'none' }}
        onClick={handleSubmit}
        disabled={!rating || isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
      <Button
        size='large'
        sx={{ textTransform: 'none' }}
        onClick={onSkip}
        disabled={isSubmitting}
      >
        Skip
      </Button>
    </Stack>
  );
}
