import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BE_SERVER_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status >= 500 &&
      !originalRequest.skipInternalErrorRedirect
    ) {
      window.location.href = '/error';
    }
    if (
      error.response.status === 403 &&
      !originalRequest.skipForbiddenRedirect
    ) {
      window.location.href = '/forbidden';
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      try {
        const res = await axios.get(
          process.env.REACT_APP_BE_SERVER_BASE_URL + '/auth/refresh',
          { headers: { Authorization: `Bearer ${refreshToken}` } },
        );
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          originalRequest.headers['Authorization'] =
            `Bearer ${res.data.accessToken}`;
          return apiClient(originalRequest);
        }
      } catch {
        window.location.href = '/login';
      }
    }
    if (originalRequest._retry) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default apiClient;
