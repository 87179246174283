export const handleInvite = () => {
  const predefinedText = encodeURIComponent(
    'I am reducing my electricity costs by up to 80%.🚀\n\n' +
      "*Here's why you should start as well:*\n" +
      '1️⃣ *Electricity cost under ₹ 3 per unit* ⚡\n' +
      '2️⃣ *₹ 78,000 to ₹ 1,08,000 in subsidy* 💸\n' +
      '3️⃣ *Up to 80% bill savings per year* 🏠💡\n\n' +
      '🌞 *Get up to ₹ 10,000 off your purchase!* 🌟\n\n' +
      '👉 *Signup using WhatsApp:* https://wa.me/918800461866?text=Hey%20VaySolar \n\n' +
      '👉 *Signup on website:* https://www.vaysolar.com/en/start',
  );
  const whatsappUrl = `https://api.whatsapp.com/send?text=${predefinedText}`;

  // Detect if the user is on mobile or desktop
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    // Open WhatsApp app or sharing drawer
    window.location.href = whatsappUrl;
  } else {
    // Open WhatsApp Web
    window.open(whatsappUrl, '_blank');
  }
};
