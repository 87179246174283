import { Box, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

const TextReview = React.memo(
  ({
    fieldName,
    setFieldValue,
    values,
    showCharCount,
    limit = 100,
    label,
    row,
    error, // New prop for error message
  }) => {
    const handleChange = (event) => {
      const newValue = event.target.value;
      // Ensure the new value does not exceed the specified limit
      if (newValue.length <= limit) {
        setFieldValue(fieldName, newValue);
      }
    };

    const currentLength = values[fieldName]?.length || 0;

    return (
      <Box display='grid' gap={2.5} mb={2}>
        <Stack direction={'column'} display={'flex'} alignItems={'end'}>
          <TextField
            size='small'
            label={label}
            sx={{
              fontSize: {
                md: '15px !important',
                sm: '14px !important',
                xs: '13px !important',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'red' : '', // Set border color based on error
                },
                '&:hover fieldset': {
                  borderColor: error ? 'red' : '',
                },
                '&.Mui-focused fieldset': {
                  borderColor: error ? 'red' : '',
                },
              },
            }}
            name={fieldName}
            multiline
            rows={row}
            fullWidth
            value={values[fieldName] || ''}
            onChange={handleChange}
            inputProps={{ maxLength: limit }}
            error={Boolean(error)} // Set error prop
          />
          {showCharCount && (
            <Typography variant='caption' color='textSecondary'>
              {currentLength} / {limit} characters
            </Typography>
          )}
          {error && (
            <Typography variant='caption' color='error'>
              {error} {/* Display error message */}
            </Typography>
          )}
        </Stack>
      </Box>
    );
  },
);

export default TextReview;
