export const PowerCutFrequency = {
  NO: 'NO',
  RARE: 'RARE',
  FREQUENT: 'FREQUENT',
};

export const RoofUsageFrequency = {
  NO: 'NO',
  RARE: 'RARE',
  FREQUENT: 'FREQUENT',
};

export const ElectricityConsumptionPattern = {
  CONSTANT: 'CONSTANT',
  VARIABLE: 'VARIABLE',
};

export const PropertyTypeEnum = {
  RESIDENTIAL: 'RESIDENTIAL',
  COMMERCIAL: 'COMMERCIAL',
};

export const VerificationStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  VERIFIED: 'VERIFIED',
};

export const Interaction = {
  CUSTOMER_INITIATED_CALL: 'CUSTOMER_INITIATED_CALL',
  VENDOR_INITIATED_CALL: 'VENDOR_INITIATED_CALL',
  SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL:
    'SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL',
  ARRANGE_VISIT_RATING: 'ARRANGE_VISIT_RATING',
};

export const Materials = {
  SOLAR_PANEL: 'SOLAR PANEL',
  INVERTER: 'INVERTER',
  SOLAR_BATTERY: 'SOLAR BATTERY',
  SOLAR_METER: 'SOLAR METER',
  DC_CABLES: 'DC CABLES',
};

export const PlantType = {
  ON_GRID: 'ONGRID',
  OFF_GRID: 'OFFGRID',
  HYBRID: 'HYBRID',
};

export const InstallationTimeline = {
  ONE_MONTH: '1 month',
  ONE_THREE_MONTHS: '1-3 months',
  THREE_PLUS_MONTHS: '3+ months',
  NOT_LOOKING: 'Not looking',
};
