import { Box } from '@mui/material';
import React from 'react';
import VendorHeader from '../vendor-details/VendorHeader';

const VendorAccordion = ({
  vendorData,
  customerId,
  siteVisitData,
  propertyId,
}) => {
  return (
    <>
      <Box pt={2}>
        {vendorData.length
          ? vendorData.map((vendor) => (
              <Box key={vendor.id} mb={1.5}>
                <VendorHeader
                  vendor={vendor}
                  customerId={customerId}
                  siteVisitData={siteVisitData}
                  propertyId={propertyId}
                />
              </Box>
            ))
          : ''}
      </Box>
    </>
  );
};

export default VendorAccordion;
