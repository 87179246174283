import { DashboardLayout } from '../layout/DashboardLayout';
import JourneyLayout from '../layout/JourneyLayout';
import { isLoggedIn } from '../utils/utils';

const VendorProfileLayout = ({ children }) => {
  return isLoggedIn() ? (
    <DashboardLayout>{children}</DashboardLayout>
  ) : (
    <JourneyLayout>{children}</JourneyLayout>
  );
};

export default VendorProfileLayout;
