import { WhatsApp } from '@mui/icons-material';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import logo from '../../../Image/logo512.png';
import { handleInvite } from './common';

export default function Referral({ onSkip }) {
  const theme = useTheme();
  return (
    <Stack
      alignItems={'center'}
      gap={2}
      sx={{
        mt: '48px',
      }}
    >
      <img
        src={logo}
        alt='Logo'
        style={{
          width: '100px',
          height: '100px',
        }}
      />
      <Typography align='center' variant='h6'>
        Your trusted end-to-end <br /> solar partner.
      </Typography>
      <Box
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          padding: '24px',
          backgroundColor: 'white',
          width: { xs: '100%', sm: '400px' },
          margin: '20px 0',
        }}
      >
        <Typography fontWeight={'bold'} variant='h5' mb={4}>
          Savings up to
          <br /> ₹ 10,00,000{' '}
          <span style={{ fontSize: '14px' }}>with solar</span>
        </Typography>
        <Typography variant='h6' mb={4}>
          Share with 5 friends, get up to <br /> ₹ 10,000 off*
        </Typography>
        <Stack>
          <Button
            variant='contained'
            sx={{ textTransform: 'none' }}
            size='large'
            startIcon={<WhatsApp />}
            onClick={handleInvite}
          >
            Invite 5 friends
          </Button>
          <Typography
            mt={1}
            variant='subtitle'
            sx={{ color: theme.palette.gray }}
          >
            *on order confirmation
          </Typography>
        </Stack>
      </Box>

      <Stack>
        <Button sx={{ textTransform: 'none' }} size='large' onClick={onSkip}>
          Not now
        </Button>
      </Stack>
    </Stack>
  );
}
