import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OffersNearYou from './OffersNearYou';
import VendorAccordion from './VendorAccordion';

export default function DashboardStepper({
  propertyDetails,
  vendorData,
  recommendations,
  userDetails,
  district,
  state,
}) {
  const theme = useTheme();
  return (
    <Box>
      <Stack spacing={2}>
        <Box
          sx={{
            maxWidth: { sm: 900, xs: 400 },
          }}
        >
          <Stack spacing={2} mt={2}>
            {/* <Box>
              <Typography
                variant='h5'
                component={'p'}
                fontSize={{
                  sm: '18px',
                  xs: '16px',
                }}
              >
                {propertyDetails?.verificationStatus ===
                VerificationStatus.PENDING
                  ? 'Verifying details'
                  : propertyDetails?.verificationStatus ===
                      VerificationStatus.REJECTED
                    ? 'Verification failed'
                    : 'Verified'}
              </Typography>
              <VerifyingStep
                propertyId={propertyDetails?.id}
                electricityBillUrl={propertyDetails?.electricityBillUrl}
                verificationStatus={propertyDetails?.verificationStatus}
                sanctionedLoad={propertyDetails?.sanctionedLoad}
              />
            </Box> */}

            {recommendations.length > 0 && (
              <Box>
                <Typography
                  fontSize={theme.typography.h5.fontSize}
                  fontWeight={'bold'}
                  mb={1.5}
                >
                  {/*TODO district, state*/}
                  Top {recommendations.length} offers
                </Typography>

                <OffersNearYou
                  recommendations={recommendations}
                  propertyDetails={propertyDetails}
                  userDetails={userDetails}
                  district={district}
                  state={state}
                />
              </Box>
            )}

            <Box>
              <Typography
                fontSize={theme.typography.h5.fontSize}
                fontWeight={'bold'}
                mt={2}
              >
                Top {vendorData.length} installers
              </Typography>
              <VendorAccordion
                vendorData={vendorData}
                customerId={propertyDetails?.userId}
                propertyId={propertyDetails?.id}
                siteVisitData={propertyDetails?.siteVisitData}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
