import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../Image/company_logo.png';
import SEO from '../layout/SEO';

export const ReviewLayout = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useNavigate();

  return (
    <>
      <SEO
        title={`${props.title || 'VaySolar'} Review`}
        description='VaySolar a platform for vendor and customer to unite world with solar power energy'
        name='VaySolar'
        type='article'
      />
      <Box sx={{ bgcolor: 'white', maxWidth: '1024px', mx: 'auto' }}>
        <Container
          sx={{
            paddingInline: '0px !important',
            boxShadow: { xs: '0px 2px 2px rgba(0, 0, 0, 0.25)', sm: 'none' },
            marginTop: { xs: '8px', sm: '0px' },
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'row'}
            height={{ xs: '48px', sm: '64px' }}
          >
            <Box
              component='header'
              sx={{
                display: { xs: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
              onClick={() => history({ pathname: '/' })}
            >
              <Box
                component='img'
                height={{ md: 48, sm: 40, xs: 36 }}
                sx={{
                  cursor: 'pointer',

                  height: '56px',
                  width: '144px',
                }}
                onClick={() => history({ pathname: '/' })}
                src={logo}
              />
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                cursor: 'pointer',
                height: '30px',
                width: '100px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                m: 1.8,
                ml: 'auto',
                '&:hover': { bgcolor: '#f0faf3' },
              }}
              color={'accent.primary'}
            ></Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ maxWidth: '1048px', mx: 'auto' }}>
        <Box>{props.children}</Box>
      </Box>
    </>
  );
};
