import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import GroupBooking from './GroupBooking';
import GroupBookingConfirmation from './GroupBookingConfirmation';
import Referral from './Referral';
import RequestConfirmation from './RequestConfirmation';

export default function ArrangeVisitFeedback({
  open,
  handleClose,
  matchId,
  district,
  state,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentComponent, setCurrentComponent] = useState(
    'RequestConfirmation',
  );

  useEffect(() => {
    if (open) {
      setCurrentComponent('RequestConfirmation');
    }
  }, [open]);

  const handleRateExperienceSubmit = () => {
    setCurrentComponent('GroupBooking');
  };

  const handleRateExperienceSkip = () => {
    setCurrentComponent('GroupBooking');
  };

  const handleGroupBookingSubmit = () => {
    setCurrentComponent('GroupBookingConfirmation');
  };

  const handleGroupBookingSkip = () => {
    setCurrentComponent('Referral');
  };

  const handleGroupBookingConfirmationSkip = () => {
    setCurrentComponent('Referral');
  };

  return (
    <Dialog
      fullScreen={isMobile}
      sx={{
        borderRadius: isMobile ? 0 : '10px',
        '& .MuiDialog-paper': {
          borderRadius: isMobile ? 0 : '10px',
          margin: isMobile ? 0 : '32px',
          maxHeight: isMobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
      open={open}
      disableEscapeKeyDown
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(0.4px)',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
          },
        },
      }}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          zIndex: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: isMobile ? 0 : '10px',
          overflow: 'auto',
          padding: isMobile ? '16px' : '20px',
          width: isMobile ? '100%' : '600px',
          height: isMobile ? '100vh' : 'auto',
          boxSizing: 'border-box',
        }}
      >
        {currentComponent === 'RequestConfirmation' && (
          <RequestConfirmation
            onSkip={handleRateExperienceSkip}
            matchId={matchId}
            onSubmit={handleRateExperienceSubmit}
          />
        )}
        {currentComponent === 'GroupBooking' && (
          <GroupBooking
            onSkip={handleGroupBookingSkip}
            onSubmit={handleGroupBookingSubmit}
            district={district}
            state={state}
            matchId={matchId}
          />
        )}
        {currentComponent === 'GroupBookingConfirmation' && (
          <GroupBookingConfirmation
            district={district}
            state={state}
            onSkip={handleGroupBookingConfirmationSkip}
            onSubmit={handleClose}
          />
        )}
        {currentComponent === 'Referral' && <Referral onSkip={handleClose} />}
      </DialogContent>
    </Dialog>
  );
}
