import { WhatsApp } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import groupOfPeopleImage from '../../../Image/group-of-people.svg';
import { handleInvite } from './common';
export default function GroupBookingConfirmation({
  district,
  state,
  onSkip,
  onSubmit,
}) {
  return (
    <Stack
      alignItems={'center'}
      gap={2}
      sx={{
        mt: '48px',
      }}
    >
      <img
        src={groupOfPeopleImage}
        alt='Group of People'
        style={{
          width: '200px',
          height: '200px',
        }}
      />
      <Typography variant='h5' fontWeight={'bold'}>
        {district}, {state}
      </Typography>
      <Typography variant='h6'>Added to the list</Typography>

      <Stack mt={8}>
        <Button
          variant='contained'
          sx={{ textTransform: 'none', mb: 1 }}
          size='large'
          startIcon={<WhatsApp />}
          onClick={() => {
            handleInvite();
            onSubmit();
          }}
        >
          Invite 5 friends
        </Button>
        <Typography>to save bills & environment</Typography>
      </Stack>

      <Button sx={{ textTransform: 'none' }} size='large' onClick={onSkip}>
        Not now
      </Button>
    </Stack>
  );
}
