import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, CircularProgress, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../layout/DashboardLayout';
import { getCustomerDetails } from '../services/CustomerService';
import { getUserDetails } from '../services/UserDetailsService';
import { formatPhoneNumber } from '../utils/utils';

const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [siteVisitData, setSiteVisitData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { id } = getUserDetails();
        const userDetails = await getCustomerDetails(id);
        setUserDetails(userDetails);
        setPropertyDetails(userDetails?.propertyDetails[0]);
        setSiteVisitData(userDetails?.propertyDetails[0]?.siteVisitData);
        setInstallationTimeline(userDetails?.propertyDetails[0]?.installationTimeline);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserDetails();
  }, []);

  const isVerified = propertyDetails?.verificationStatus === 'VERIFIED';

  return (
    <DashboardLayout announcementBar={true} propertyDetails={propertyDetails} installationTimeline={installationTimeline}>
      <Box sx={{ p: 3 }}>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Typography variant='h4' gutterBottom sx={{ color: 'green' }}>
              Hi, {userDetails.fullName || 'N/A'}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 3, mb: 3 }}>
                  <Typography variant='h6' gutterBottom sx={{ color: 'green' }}>
                    Personal Information
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    <strong>Name:</strong> {userDetails?.fullName || 'N/A'}
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    <strong>Phone Number:</strong>{' '}
                    {formatPhoneNumber(userDetails?.phoneNumber)}
                  </Typography>
                </Paper>
                <Paper sx={{ p: 3, mb: 3 }}>
                  <Typography variant='h6' gutterBottom sx={{ color: 'green' }}>
                    Property Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>PinCode:</strong>{' '}
                        {propertyDetails?.pinCode || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Roof top Area (Shadow Free):</strong>{' '}
                        {propertyDetails?.shadowFreeArea
                          ? `${propertyDetails.shadowFreeArea} sqft`
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Bill Amount:</strong>{' '}
                        {propertyDetails?.billAmount
                          ? `₹${propertyDetails.billAmount}`
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Bill Month:</strong>{' '}
                        {propertyDetails?.billMonth || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Sanctioned Load:</strong>{' '}
                        {propertyDetails?.sanctionedLoad
                          ? `${propertyDetails.sanctionedLoad} KW`
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Electricity Bill URL:</strong>
                        {propertyDetails?.electricityBillUrl ? (
                          <a
                            href={propertyDetails?.electricityBillUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            View Bill
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack direction={'row'} gap={'0.5rem'}>
                      <Typography variant='body1' gutterBottom>
                      <strong>Verification Status:</strong>
                      </Typography>
                      {isVerified ? (
                          <CheckCircleIcon color='success' />
                        ) : (
                          <CancelIcon color='error' />
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 3 }}>
                  <Typography variant='h6' gutterBottom sx={{ color: 'green' }}>
                    Site Visit Data
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Floor:</strong> {siteVisitData?.floor || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Roof Type:</strong>{' '}
                        {siteVisitData?.roofType || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Roof Usage:</strong>{' '}
                        {siteVisitData?.roofUsage || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Roof Top Area:</strong>{' '}
                        {siteVisitData?.roofTopArea
                          ? `${siteVisitData.roofTopArea} sqft`
                          : 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant='body1' gutterBottom>
                        <strong>Power Cut Frequency:</strong>{' '}
                        {siteVisitData?.powerCutFrequency || 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ProfilePage;
