import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import TextReview from '../components/reviewComponent/ReviewTextBox';
import WriteNowImg from '../Image/writeReview.png';
import { ReviewLayout } from '../layoutV2/ReviewLayout';
import {
  addDocument,
  getVendorName,
  updateReviewerVerificationDetails,
} from '../services/CustomerService';

function FileUpload({
  installationFiles,
  setInstallationFiles,
  fileSelectionError,
  setFileSelectionError,
}) {
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const handleFileChange = async (event) => {
    setFileSelectionError('');
    const filesArray = Array.from(event.target.files);

    if (filesArray.length + installationFiles.length > 5) {
      setFileSelectionError('Maximum 5 files allowed.');
      return;
    }

    const installationProofFiles = filesArray.filter((file) => {
      return !installationFiles.some(
        (selectedFile) => selectedFile.name === file.name,
      );
    });

    let totalSize = installationProofFiles.reduce(
      (accumulator, file) => accumulator + file.size,
      0,
    );

    const validFiles = installationProofFiles.filter((file) => {
      if (file.size > 10 * 1024 * 1024) {
        setFileSelectionError(`File ${file.name} exceeds 10 MB size limit.`);
        return false;
      }
      if (totalSize + file.size > 10 * 1024 * 1024) {
        setFileSelectionError('Total size of selected files exceeds 10 MB.');
        return false;
      }
      return true;
    });

    setInstallationFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleFileDeleteChip = (fileToDelete) => () => {
    setFileSelectionError('');
    setInstallationFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete),
    );
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    return `${fileName.substr(0, maxLength)}...`;
  };

  return (
    <>
      {installationFiles.length > 0 && (
        <Box
          display='flex'
          alignItems='center'
          gap={1}
          maxWidth={'1000px'}
          flexWrap='wrap'
        >
          {installationFiles.map((file, index) => (
            <Chip
              key={index}
              label={truncateFileName(file.name)}
              onDelete={handleFileDeleteChip(file)}
              color='primary'
              variant='outlined'
              style={{
                margin: '4px',
                display: 'flex',
                flexDirection: 'row-reverse',
                padding: '16px',
              }}
              title={file.name} // Show full filename on hover
            />
          ))}
        </Box>
      )}
      <Box display='flex' alignItems='center' gap={1}>
        <input
          type='file'
          accept='.jpg, .jpeg, .png, .pdf, .docx'
          style={{ display: 'none' }} // Hide the file input
          onChange={handleFileChange}
          ref={fileInputRef}
          multiple
        />
        <Button
          component='label'
          variant='text'
          startIcon={<AddCircleIcon style={{ fontSize: '40px' }} />}
          disableElevation
          onClick={() => fileInputRef.current.click()}
          sx={{
            textTransform: 'none',
          }}
        >
          <Stack>
            <Typography
              sx={{
                fontSize: 'h6',
              }}
            >
              Upload supporting proofs
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: theme.typography.caption.fontSize,
                  md: theme.typography.caption.fontSize,
                },
              }}
            >
              Only for verification, not published
            </Typography>
          </Stack>
        </Button>
        {fileSelectionError && (
          <Typography variant='body2' color='error'>
            {fileSelectionError}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography
          mb={1}
          sx={{
            fontSize: {
              xs: theme.typography.caption.fontSize,
              md: theme.typography.caption.fontSize,
            },
          }}
        >
          <b>Note</b>: We use your email for review identity confirmation and
          anti-spam purposes. We will not use your email for marketing, and not
          sell, trade or provide it to any third parties.
        </Typography>
      </Box>
    </>
  );
}

function CustomizedDialogs({ isModalOpen }) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(isModalOpen);
  const handleClose = () => {
    window.location.href = 'https://www.vaysolar.com';
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack direction={'column'} alignItems={'center'} gap={1.5} p={2}>
          <CheckCircleIcon sx={{ color: 'primary.main', fontSize: '10rem' }} />
          <Typography>Thank you for providing the feedback.</Typography>
          <Typography>You may close this page.</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function VerifiedReviewsPage() {
  const [fileSelectionError, setFileSelectionError] = useState('');
  const [installationFiles, setInstallationFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const onMount = async () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const extractedVendorId = params.get('vendorId');
      const extractedReviewId = params.get('reviewId');
      setVendorId(extractedVendorId);
      setReviewId(extractedReviewId);
    };
    onMount();
  }, []);

  useEffect(() => {
    const fetchVendorData = async () => {
      if (vendorId && reviewId) {
        try {
          const data = await getVendorName(reviewId, vendorId);
        } catch (error) {
          console.error('Failed to fetch vendor data:', error);
        }
      }
    };

    fetchVendorData();
  }, [vendorId, reviewId]); // Depend on vendorId and reviewId

  const formik = useFormik({
    initialValues: {
      email: '',
      plantSize: '',
      panCardNumber: '',
    },
    validationSchema: Yup.object({
      plantSize: Yup.number('Plant size must be a number')
        .positive('Plant size must be positive')
        .test((obj) => obj && /^\d+(\.\d{0,2})?$/.test(obj))
        .required('Plant size is required'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      panCardNumber: Yup.string()
        .matches(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/, 'Invalid PAN card format')
        .notRequired(),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const uploadPromises = [];
        const verificationDetails = {
          email: values.email,
          panCardNumber: values.panCardNumber,
          plantSize: values.plantSize,
          isUploadedSupportingProof:
            installationFiles.length > 0 ? true : false,
        };

        const res = await updateReviewerVerificationDetails(
          vendorId,
          reviewId,
          verificationDetails,
        );
        installationFiles.forEach(async (file) => {
          const document = new FormData();
          document.append('entityID', res.id);
          document.append('entity', 'REVIEW INSTALLATION PROOF');
          document.append(
            'name',
            'reviewer installation proof' +
              ' | ' +
              res.id +
              ' | ' +
              vendorId +
              ' | ' +
              reviewId,
          );
          document.append(
            'description',
            values.email +
              ' | ' +
              values.panCardNumber +
              ' |  ' +
              values.plantSize +
              ' | ' +
              vendorId +
              ' | ' +
              'reviewer_installation_proof' +
              ' | ' +
              new Date(),
          );
          document.append('type', 'REVIEW');
          document.append('file', file);

          uploadPromises.push(addDocument(document, vendorId));
        });

        await Promise.all(uploadPromises);
        setIsModalOpen(true);
      } catch (error) {
        console.error('Error submitting form:', error);
      }
      setIsLoading(false);
    },
    enableReinitialize: true,
  });
  const theme = useTheme();

  return (
    <ReviewLayout title='Verification'>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          component='main'
          pt={2}
          sx={{ paddingInline: { md: '24px', xs: '12px' } }}
        >
          <CustomizedDialogs isModalOpen={isModalOpen} />

          <Box display={'flex'}>
            <Box display={'grid'} gap={2}>
              <Box display='flex'>
                <Box display='flex' alignItems='center' mb={4}>
                  <AdminPanelSettingsIcon
                    fontSize='100px'
                    sx={{
                      fontSize: '100px',
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.h5.fontSize,
                          md: theme.typography.h5.fontSize,
                        },
                        fontWeight: theme.typography.h1.fontWeight,
                        color: theme.palette.primary.main,
                      }}
                    >
                      Only verified reviews are published
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.caption.fontSize,
                          md: theme.typography.caption.fontSize,
                        },
                        fontWeight: theme.typography.h1.fontWeight,
                      }}
                    >
                      You must be a customer of the installer
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              <Stack direction={'column'} gap={1}>
                <TextReview
                  fieldName='email'
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                  limit={50}
                  label={'Your email address *'}
                  row={1}
                  error={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : undefined
                  }
                />

                <TextReview
                  fieldName='panCardNumber'
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                  limit={20}
                  label={'PAN Card Number *'}
                  row={1}
                  error={
                    formik.errors.panCardNumber && formik.touched.panCardNumber
                      ? formik.errors.panCardNumber
                      : undefined
                  }
                />

                <TextReview
                  fieldName='plantSize'
                  setFieldValue={formik.setFieldValue}
                  values={formik.values}
                  limit={20}
                  label={'Plant size *'}
                  row={1}
                  error={
                    formik.errors.plantSize && formik.touched.plantSize
                      ? formik.errors.plantSize
                      : undefined
                  }
                />
              </Stack>

              <FileUpload
                installationFiles={installationFiles}
                setInstallationFiles={setInstallationFiles}
                fileSelectionError={fileSelectionError}
                setFileSelectionError={setFileSelectionError}
              />
            </Box>
            <Box
              component='img'
              height={{ lg: '365px', md: '350px' }}
              src={WriteNowImg}
              display={{ xs: 'none', md: 'block' }}
              paddingTop={'35px'}
            />
          </Box>
          <Stack>
            <Box display='flex' justifyContent='end' alignItems='start'>
              <Stack alignItems='center' width='170px'>
                <Button
                  type='submit'
                  variant='contained'
                  disableElevation
                  endIcon={
                    <ArrowForwardIos sx={{ height: '16px', width: '20px' }} />
                  }
                  sx={{ width: '170px' }}
                  onClick={formik.handleSubmit}
                >
                  Confirm
                </Button>
                <Typography variant='caption'>for verification</Typography>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </ReviewLayout>
  );
}

export default VerifiedReviewsPage;
