import { VerifiedUser } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import logo from '../../../Image/logo512.png';
import RateExperience from './RateExperience';

export default function RequestConfirmation({ onSkip, onSubmit, matchId }) {
  return (
    <Stack
      alignItems={'center'}
      gap={2}
      sx={{
        mt: '24px',
      }}
    >
      <VerifiedUser
        sx={{
          fontSize: '100px',
          color: 'green',
        }}
      />
      <Typography fontWeight={'bold'} variant='h5'>
        Request received.
      </Typography>
      <Typography variant='h6' mb={4}>
        Team will reach you in 2 days
      </Typography>
      <RateExperience onSkip={onSkip} onSubmit={onSubmit} matchId={matchId} />
      <img
        src={logo}
        alt='Logo'
        style={{
          width: '100px',
          height: '100px',
        }}
      />
      <Typography align='center' variant='h6'>
        Your trusted end-to-end <br /> solar partner.
      </Typography>
    </Stack>
  );
}
